<template>
  <div>
    <House>MEET THE TEAM</House>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="2" class="text-center"><img src="/gears.png" style="max-width: 100%;"></v-col>
        <v-col cols="12" sm="4" class="text-center">We are a highly diverse team with backgrounds in residential and commercial inspections, construction management, electrical and mechanical engineering, and project management. Our business comes with 25+ years of collective experience in professional services both in the military/government and private sector. We understand the needs of our clients and strive for prompt turnaround with exceptional service and product quality.</v-col>
        <v-col cols="12" sm="2" class="text-center"><img src="/hands.png" style="max-width: 100%;"></v-col>
        <v-col cols="12" sm="4" class="text-center">Our inspectors are seasoned experts in the construction industry with extensive knowledge to understand buyer concerns. Inspectors are equipped with smart devices and high-resolution cameras. They utilize exceptional reporting methods for full transparency on all inspection reports. Our business leverages digital processes for real-time, traditional, and online inspection reports.</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2" class="text-center">
          <img style="max-width: 166px; border: 3px solid #5a7bac; border-radius: 20px;" src="/justin_shaw.jpg"><br>
          <span style="font-weight: bold">Owner & Operator<br>
          Electrical Engineer<br>
            Inspector</span>
        </v-col>
        <v-col cols="12" md="4">
          <h2>MEET JUSTIN SHAW</h2>
          <p class="text-center text-caption">Justin leads the company’s operations, construction inspections, and
            management team, with responsibility for business in the greater San Antonio and Hill Country surrounding
            areas. He oversees the company’s business development efforts  to serve a wider range of clients across Texas.</p>
          <p class="text-center text-caption">He also serves as a senior electrical engineer for NuStar Energy where he
            leads electrical teams in the design, development, and execution of capital strategic projects for pipeline
            installations and crude oil gathering systems.  He is a well-rounded manager in industrial automation, power
            distribution, and construction management. Throughout his career, Justin led organizations to success with
            his ability to streamline processes and engineering practices. His business acumen extends beyond his
            engineering background to management of personnel, construction schedules, cost performance, and quality
            management.</p>
          <p class="text-center text-caption">Justin also served in the U.S. Navy as a P-3 Flight Engineer performing
            missions supporting maritime patrol and reconnaissance through several joint operations, including Operation
            Enduring Freedom and the Iraqi Campaign. Justin holds a Master’s degree in business administration from the
            University of Texas at San Antonio and holds a Bachelor’s degree in electrical engineering from Capital College.
          </p>
        </v-col>
        <v-col cols="12" md="2" class="text-center">
          <img style="max-width: 166px; border: 3px solid #5a7bac; border-radius: 20px;" src="/ross_penney.jpg"><br>
          <span style="font-weight: bold">Partner<br>
          Mechanical Engineer<br>
          Professional Home Inspector<br>
            (License #25051)</span>
        </v-col>
        <v-col cols="12" md="4">
          <h2>MEET ROSS PENNEY</h2>
            <p class="text-center text-caption">
              Ross is the licensed Professional Home Inspector for Allgood Inspections. Ross takes an immense amount of
              pride in serving all of his clients, and team. He also works as a Project Engineer with NuStar Energy in
              San Antonio, TX where his role oversees the design, development, and execution of Capital Strategic
              projects for pipeline installations and crude oil gathering systems.</p>
            <p class="text-center text-caption">
              His professional engineering experience paired with his professional home inspector role makes him an
              exceptional inspector, in that, he carries a consciousness of safety and attention to detail. He is able
              to effectively communicate technical jargon into understandable details for clients.</p>
            <p class="text-center text-caption">
              Ross earned his Bachelor’s degree in Mechanical Engineering from the University of Texas – San Antonio.
              Ross earned his Professional Home Inspector license with Champion’s School of Real Estate – San Antonio.
              During his free time, Ross enjoys spending time with his wife and two dogs. In his free time, Ross enjoys
              outdoor activities such as reel mowing, hunting, and spending time with his wife. </p></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import House from "../components/House";
export default {
  name: "About",
  components: {House}
}
</script>

<style scoped>

</style>
